<template>
  <div class="contact-section">
    <div class="contact-section__image d-none d-lg-block">
      <slot name="image" />
    </div>
    <h4 class="contact-section__title">
      <slot name="title" />
    </h4>
    <div class="contact-section__body">
      <slot name="body" />
    </div>
    <div class="contact-section__email-address">
      <a :href="mailToHref">{{ emailAddress }}</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ContactSection',
  props: {
    emailAddress: { type: String, default: null, required: true }
  },
  computed: {
    mailToHref () {
      return `mailto:${this.emailAddress}`
    }
  }
}
</script>

<style lang="scss" scoped>
.contact-section {
  text-align: left;
  border-radius: 18px;
  border: 1px solid transparent;
  height: 100%;
  padding: 1rem;
  position: relative;

  &__image {
    width: 100%;
    position: absolute;
    left: 0;
    top: -31px;
  }

  &__email-address {
    font-weight: 600;
  }

  @include media-breakpoint-up(lg) {
    text-align: center;

    &__title {
      font-size: 1.3rem;
      margin-top: 2rem;
    }
  }
}
</style>
