<template>
  <div class="container page-min-height">
    <div v-if="pageDataLoaded === true">
      <h2 class="page-title mb-4">
        <oc-content :item="$oc.content.contactPageTitle" />
      </h2>
      <div class="row">
        <div class="col-12 col-lg-4 flex-wrap mb-3 mb-md-4">
          <contact-section
            class="contact__section--1"
            :email-address="section1EmailAddress"
          >
            <template v-slot:image>
              <oc-content :item="$oc.content.contactPageSection1Image" />
            </template>
            <template v-slot:title>
              <oc-content :item="$oc.content.contactPageSection1Title" />
            </template>
            <template v-slot:body>
              <oc-content :item="$oc.content.contactPageSection1Body" />
            </template>
          </contact-section>
        </div>
        <div class="col-12 col-lg-4 flex-wrap mb-3 mb-md-4">
          <contact-section
            class="contact__section--2"
            :email-address="section2EmailAddress"
          >
            <template v-slot:image>
              <oc-content :item="$oc.content.contactPageSection2Image" />
            </template>
            <template v-slot:title>
              <oc-content :item="$oc.content.contactPageSection2Title" />
            </template>
            <template v-slot:body>
              <oc-content :item="$oc.content.contactPageSection2Body" />
            </template>
          </contact-section>
        </div>
        <div class="col-12 col-lg-4 flex-wrap mb-3 mb-md-4">
          <contact-section
            class="contact__section--3"
            :email-address="section3EmailAddress"
          >
            <template v-slot:image>
              <oc-content :item="$oc.content.contactPageSection3Image" />
            </template>
            <template v-slot:title>
              <oc-content :item="$oc.content.contactPageSection3Title" />
            </template>
            <template v-slot:body>
              <oc-content :item="$oc.content.contactPageSection3Body" />
            </template>
          </contact-section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ContactSection from '../components/ContactPage/ContactSection'
import { getOrchardCoreContentModule } from '../lib/api'
import pageMixin from '../lib/mixins/page'

export default {
  name: 'Contact',
  components: {
    ContactSection
  },
  mixins: [pageMixin],
  computed: {
    section1EmailAddress () {
      return this.$oc.getSelectedLocalization(
        this.$oc.content.contactPageSection1EmailAddress.localization
      ).content
    },
    section2EmailAddress () {
      return this.$oc.getSelectedLocalization(
        this.$oc.content.contactPageSection2EmailAddress.localization
      ).content
    },
    section3EmailAddress () {
      return this.$oc.getSelectedLocalization(
        this.$oc.content.contactPageSection3EmailAddress.localization
      ).content
    }
  },
  async mounted () {
    try {
      const response = await getOrchardCoreContentModule('contact-page')
      this.$oc.setContent(response.data)
      this.pageDataLoaded = true
    } catch {
      this.pageDataLoaded = false
    }
  },
  methods: {
    generateMailToHref (emailAddress) {
      return `mailto:${emailAddress}`
    }
  },
  metaInfo: {
    title: 'Benefit Buddy - Contact Us'
  }
}
</script>

<style lang="scss" scoped>
.contact {
  &__section {
    text-align: left;
    border-radius: 18px;
    border: 1px solid transparent;
    height: 100%;
    padding: 1rem;

    &--1 {
      background-color: rgba(87, 134, 239, 0.1);
    }

    &--2 {
      background-color: rgba(235, 253, 246, 1);
    }

    &--3 {
      background-color: rgba(248, 244, 253, 1);
    }
  }

  @include media-breakpoint-up(lg) {
    &__section {
      text-align: center;

      &-title {
        font-size: 1.3rem;
      }
    }
  }
}

@include media-breakpoint-up(lg) {
  .page-title {
    font-size: 1.9rem;
    text-align: center;
    margin-bottom: 4rem !important;
  }
}
</style>
